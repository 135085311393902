import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
// @types
import { AiState } from '../../@types/ai';
//
import { dispatch } from '../store';
import { getAuthId } from '../../contexts/FirebaseContext';
// ----------------------------------------------------------------------

const initialState: AiState = {
  isLoading: true,
  error: null,
  emails: [],
  introEmails: [],
  followupEmails: [],
  valueEmails: [],
  playground: [],
};

const slice = createSlice({
  name: 'sellers',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Email
    getEmailsSuccess(state, action) {
      state.isLoading = false;
      state.emails = action.payload;
    },

    getIntroEmailsSuccess(state, action) {
      state.isLoading = false;
      state.introEmails = action.payload;
    },

    getFollowupEmailsSuccess(state, action) {
      state.isLoading = false;
      state.followupEmails = action.payload;
    },

    getValueEmailsSuccess(state, action) {
      state.isLoading = false;
      state.valueEmails = action.payload;
    },

    getPlaygroundEmailsSuccess(state, action) {
      state.isLoading = false;
      state.playground = action.payload;
    },

    // CREATE Email
    createEmailsSuccess(state, action) {
      const newEmail = action.payload;
      // console.log(action);
      state.isLoading = false;
      state.emails = [newEmail, ...state.emails];
    },

    // CREATE Email
    createIntroEmailsSuccess(state, action) {
      const newEmail = action.payload;
      // console.log(action);
      state.isLoading = false;
      state.introEmails = [newEmail, ...state.introEmails];
    },

    // CREATE Email
    createFollowupEmailsSuccess(state, action) {
      const newEmail = action.payload;
      // console.log(action);
      state.isLoading = false;
      state.followupEmails = [newEmail, ...state.followupEmails];
    },

    createValueEmailsSuccess(state, action) {
      const newEmail = action.payload;
      // console.log(action);
      state.isLoading = false;
      state.valueEmails = [newEmail, ...state.valueEmails];
    },

    createPlaygroundSuccess(state, action) {
      const newEmail = action.payload;
      // console.log(action);
      state.isLoading = false;
      state.playground = [newEmail, ...state.playground];
    },

    // UPDATE Email
    updateEmailSuccess(state, action) {
      const email = action.payload;
      const updateEvent = state.emails.map((_email) => {
        if (_email.id === email.id) {
          return email;
        }
        return _email;
      });

      state.isLoading = false;
      // state.emails = updateEmails;
    },

    updateEmailRateSuccess(state, action) {
      const { goodResponse, aiId } = action.payload;
      const updateEvent = state.emails.map((_email) => {
        if (_email.id === aiId) {
          _email.goodResponse = goodResponse;
          return _email;
        }
        return _email;
      });
      const updateEvent2 = state.introEmails.map((_email) => {
        if (_email.id === aiId) {
          _email.goodResponse = goodResponse;
          return _email;
        }
        return _email;
      });
      const updateEvent3 = state.followupEmails.map((_email) => {
        if (_email.id === aiId) {
          _email.goodResponse = goodResponse;
          return _email;
        }
        return _email;
      });
      const updateEvent4 = state.valueEmails.map((_email) => {
        if (_email.id === aiId) {
          _email.goodResponse = goodResponse;
          return _email;
        }
        return _email;
      });
      const updateEvent5 = state.playground.map((_email) => {
        if (_email.id === aiId) {
          _email.goodResponse = goodResponse;
          return _email;
        }
        return _email;
      });

      state.isLoading = false;
      state.emails = updateEvent;
      state.introEmails = updateEvent2;
      state.followupEmails = updateEvent3;
      state.valueEmails = updateEvent4;
      state.playground = updateEvent5;
    },

    // DELETE EVENT
    deleteEmailsSuccess(state, action) {
      const emailId = action.payload;
      const deleteEmails = state.emails.filter((email) => email.id !== emailId);
      state.emails = deleteEmails;
      state.isLoading = false;
      // console.log(state.emails);
    },
    deleteIntroEmailsSuccess(state, action) {
      const emailId = action.payload;
      const deleteEmails = state.introEmails.filter((email) => email.id !== emailId);
      state.introEmails = deleteEmails;
      state.isLoading = false;
      // console.log(state.emails);
    },
    deleteFollowupEmailsSuccess(state, action) {
      const emailId = action.payload;
      const deleteEmails = state.followupEmails.filter((email) => email.id !== emailId);
      state.followupEmails = deleteEmails;
      state.isLoading = false;
      // console.log(state.emails);
    },
    deleteValueEmailsSuccess(state, action) {
      const emailId = action.payload;
      const deleteEmails = state.valueEmails.filter((email) => email.id !== emailId);
      state.valueEmails = deleteEmails;
      state.isLoading = false;
      // console.log(state.emails);
    },
    deletePlaygroundSuccess(state, action) {
      const emailId = action.payload;
      const deleteEmails = state.playground.filter((email) => email.id !== emailId);
      state.playground = deleteEmails;
      state.isLoading = false;
      // console.log(state.emails);
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function getEmails(aiType: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let authToken = await getAuthId();
      const response = await axios.get(`/ai/${aiType}`, {
        params: {
          userId: authToken,
        },
      });
      // console.log(response.data);
      if (aiType === 'introEmail') {
        dispatch(slice.actions.getIntroEmailsSuccess(response.data));
      } else if (aiType === 'followupEmail') {
        dispatch(slice.actions.getFollowupEmailsSuccess(response.data));
      } else if (aiType === 'valueEmail') {
        dispatch(slice.actions.getValueEmailsSuccess(response.data));
      } else if (aiType === 'playground') {
        dispatch(slice.actions.getPlaygroundEmailsSuccess(response.data));
      } else {
        dispatch(slice.actions.getEmailsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createEmails(
  useCase: string,
  tone: string,
  type: string,
  lengthEmail: string,
  callToAction: string,
  reciever: string
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let authToken = await getAuthId();
      const response = await axios.post('/ai/generateEmail', {
        useCase,
        tone,
        type,
        length: lengthEmail,
        callToAction,
        reciever,
        userId: authToken,
      });
      if (response.data) {
        dispatch(slice.actions.createEmailsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createIntoEmails(
  targetCustomer: string,
  tone: string,
  type: string,
  lengthEmail: string,
  callToAction: string,
  valueProp: string
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let authToken = await getAuthId();
      const response = await axios.post('/ai/generateEmail/intro', {
        targetCustomer,
        tone,
        type,
        length: lengthEmail,
        callToAction,
        valueProp,
        userId: authToken,
      });
      if (response.data) {
        dispatch(slice.actions.createIntroEmailsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createFollowupEmails(
  introEmail: string,
  tone: string,
  type: string,
  lengthEmail: string,
  callToAction: string,
  valueProp: string
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let authToken = await getAuthId();
      const response = await axios.post('/ai/generateEmail/followup', {
        introEmail,
        tone,
        type,
        length: lengthEmail,
        callToAction,
        valueProp,
        userId: authToken,
      });
      if (response.data) {
        dispatch(slice.actions.createFollowupEmailsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createValueEmails(
  industry: string,
  tone: string,
  type: string,
  lengthEmail: string,
  callToAction: string,
  valueProp: string
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let authToken = await getAuthId();
      const response = await axios.post('/ai/generateEmail/value', {
        industry,
        tone,
        type,
        length: lengthEmail,
        callToAction,
        valueProp,
        userId: authToken,
      });
      if (response.data) {
        dispatch(slice.actions.createValueEmailsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createPlayground(script: string, isBiz: boolean) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let authToken = await getAuthId();
      const response = await axios.post('/ai/playground', {
        script,
        companyId: authToken,
        isBiz,
      });
      if (response.data) {
        dispatch(slice.actions.createPlaygroundSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function updateEmailRate(goodResponse: boolean, aiId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/ai/rate', {
        goodResponse,
        aiId,
      });
      dispatch(slice.actions.updateEmailRateSuccess({ goodResponse, aiId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function updateEmails(goodResponse: boolean, aiId: string) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post('/ai/save', {
//         goodResponse,
//         aiId,
//       });
//       dispatch(slice.actions.updateEmailRateSuccess({ goodResponse, aiId }));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// ----------------------------------------------------------------------

export function deleteEmails(aiId: string, aiType?: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = axios.post('/ai/delete', {
        aiId,
      });
      // console.log(response.data);
      if (aiType === 'introEmail') {
        dispatch(slice.actions.deleteIntroEmailsSuccess(aiId));
      } else if (aiType === 'followupEmail') {
        dispatch(slice.actions.deleteFollowupEmailsSuccess(aiId));
      } else if (aiType === 'valueEmail') {
        dispatch(slice.actions.deleteValueEmailsSuccess(aiId));
      } else if (aiType === 'playground') {
        dispatch(slice.actions.deletePlaygroundSuccess(aiId));
      } else {
        dispatch(slice.actions.deleteEmailsSuccess(aiId));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
