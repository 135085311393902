// routes
import { PATH_AUTH, PATH_PAGE } from '../../routes/paths';
// components
// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  // {
  //   title: 'Home',
  //   path: PATH_PAGE.root,
  //   icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
  // },
  {
    title: 'Companies',
    path: PATH_PAGE.root,
    icon: <Iconify icon={'eva:people-fill'} {...ICON_SIZE} />,
  },
  // {
  //   title: 'Sellers',
  //   path: PATH_PAGE.sellers,
  //   icon: <Iconify icon={'eva:people-fill'} {...ICON_SIZE} />,
  // },
  {
    title: 'Apply',
    path: PATH_AUTH.registerCompany,
    icon: <Iconify icon={'eva:edit-2-fill'} {...ICON_SIZE} />,
  },
];

export default menuConfig;
