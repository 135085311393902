import { createContext, ReactNode, useEffect, useReducer, useState } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  DocumentData,
  updateDoc,
} from 'firebase/firestore';
// @types
import { ActionMap, AuthState, AuthUser, FirebaseContextType } from '../@types/auth';
//
import { FIREBASE_API } from '../config';
import {
  getStorage,
  ref,
  uploadBytes,
  listAll,
  getDownloadURL,
  getMetadata,
} from 'firebase/storage';
import { getAnalytics, logEvent } from 'firebase/analytics';

// ----------------------------------------------------------------------

const ADMIN_EMAILS = [
  'demo@growwithseller.com',
  'nolancassidy1@gmail.com',
  'nolancassidy1+backtst@gmail.com',
  'nolancassidy1+seller@gmail.com',
  'peterjfreeman12@gmail.com',
  'peter@growwithseller.com',
  'hello+seller1@growwithseller.com',
  'nolancassidy1+company@gmail.com',
];

const firebaseApp = initializeApp(FIREBASE_API);
const analytics = getAnalytics();

logEvent(analytics, 'User Connected');
const AUTH = getAuth(firebaseApp);

const DB = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

enum Types {
  Initial = 'INITIALISE',
}

type FirebaseAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
};

type FirebaseActions = ActionMap<FirebaseAuthPayload>[keyof ActionMap<FirebaseAuthPayload>];

const reducer = (state: AuthState, action: FirebaseActions) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

const AuthContext = createContext<FirebaseContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [profile, setProfile] = useState<DocumentData | undefined>();

  useEffect(
    () =>
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          const userRef = doc(DB, 'users', user.uid);

          const docSnap = await getDoc(userRef);

          if (docSnap.exists()) {
            setProfile(docSnap.data());
          }

          dispatch({
            type: Types.Initial,
            payload: { isAuthenticated: true, user },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: { isAuthenticated: false, user: null },
          });
        }
      }),
    [dispatch]
  );

  const login = (email: string, password: string) =>
    signInWithEmailAndPassword(AUTH, email, password).then(async (res) => {
      const userRef = doc(collection(DB, 'users'), res.user?.uid);
      await updateDoc(userRef, {
        timeLastLoggedIn: Date.now(),
      });
      return res;
    });

  const register = (
    phoneNumber: string,
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    link: string
  ) =>
    createUserWithEmailAndPassword(AUTH, email, password).then(async (res) => {
      const userRef = doc(collection(DB, 'users'), res.user?.uid);

      await setDoc(userRef, {
        uid: res.user?.uid,
        phoneNumber,
        email,
        displayName: `${firstName} ${lastName}`,
        isSeller: true,
        firstName,
        lastName,
        link,
        disabled: true,
        timeAccountCreated: Date.now(),
      });
    });

  const registerCompany = (
    companyName: string,
    phoneNumber: string,
    email: string,
    password: string,
    firstName: string,
    lastName: string
    // link: string
  ) =>
    createUserWithEmailAndPassword(AUTH, email, password).then(async (res) => {
      const userRef = doc(collection(DB, 'users'), res.user?.uid);

      await setDoc(userRef, {
        uid: res.user?.uid,
        phoneNumber,
        email,
        displayName: `${firstName} ${lastName}`,
        isSeller: false,
        firstName,
        lastName,
        companyName,
        // link,
        disabled: true,
        timeAccountCreated: Date.now(),
      });
    });

  const logout = () => signOut(AUTH);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          id: state?.user?.uid,
          email: state?.user?.email,
          stripeId: state?.user?.stripeId || profile?.stripeId || null,
          onboarded: state?.user?.onboarded || profile?.onboarded || false,
          isSeller: state?.user?.isSeller || profile?.isSeller,
          photoURL: state?.user?.photoURL || profile?.photoURL,
          displayName: state?.user?.displayName || profile?.displayName,
          companyName: state?.user?.isSeller ? state?.user?.displayName : profile?.companyName,
          role: ADMIN_EMAILS.includes(state?.user?.email) ? 'admin' : 'user',
          phoneNumber: state?.user?.phoneNumber || profile?.phoneNumber || '',
          country: profile?.country || '',
          address: profile?.address || '',
          state: profile?.state || '',
          city: profile?.city || '',
          zipCode: profile?.zipCode || '',
          about: profile?.about || '',
          link: profile?.link || '',
          timeline: profile?.timeline || '',
          commission: profile?.commission || '',
          meetFee: profile?.meetFee || '',
          fundingAmount: profile?.fundingAmount || '',
          employeeSize: profile?.employeeSize || '',
          industry: profile?.industry || '',
          isHiddenFromApply: profile?.isHiddenFromApply || false,
          isPublic: profile?.isPublic || false,
          subscriptionStatus: state?.user?.subscriptionStatus === 'active' ? 'active' : 'disabled',
          lastCompleteSurvey: profile?.lastCompleteSurvey || 0,
          useCases: profile?.useCases || [],
          savedEmails: profile?.emails || [],
          saasExperience: profile?.saasExperience || '',
          salesExperience: profile?.salesExperience || '',
          currentTitle: profile?.currentTitle || '',
          avgDeal: profile?.avgDeal || '',
          dealLength: profile?.dealLength || '',
          lastYearQuota: profile?.lastYearQuota || '',
          quotaAttained: profile?.quotaAttained || '',
          fullTimeJobs: profile?.fullTimeJobs || [],
          typeOfProducts: profile?.typeOfProducts || [],
          interestedRoles: profile?.interestedRoles || [],
          departmentsSoldTo: profile?.departmentsSoldTo || [],
          personasSoldTo: profile?.personasSoldTo || [],
          companySizeSoldTo: profile?.companySizeSoldTo || [],
          employmentType: profile?.employmentType || [],
          disabled: profile?.disabled || false,
          isLookingForContract: profile?.isLookingForContract,
        },
        login,
        register,
        logout,
        registerCompany,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

async function getAuthToken() {
  let token = await AUTH.currentUser!.getIdToken(/* forceRefresh */ true);
  return token;
}

async function getAuthId() {
  return AUTH.currentUser?.uid;
}

export {
  doc,
  AuthContext,
  AuthProvider,
  getAuthToken,
  getAuthId,
  storage,
  ref,
  uploadBytes,
  listAll,
  getDownloadURL,
  getMetadata,
  updateDoc,
  DB,
  collection,
  logEvent,
  analytics,
};
