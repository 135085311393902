import { createSlice } from '@reduxjs/toolkit';
// import { EventInput } from '@fullcalendar/common';
// utils

import axios from '../../utils/axios';
// @types
import { JobsState } from '../../@types/jobs';
//
import { dispatch } from '../store';
import { getAuthId } from '../../contexts/FirebaseContext';
// ----------------------------------------------------------------------

const initialState: JobsState = {
  isLoading: true,
  error: null,
  jobs: [],
  payments: [],
  outreach: [],
  outreachEdit: [],
  surveys: [],
  meet: [],
};

const slice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.surveys = [];
      state.outreach = [];
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET JOBS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.jobs = action.payload;
    },
    // GET JOBS
    getPaymentsSuccess(state, action) {
      state.isLoading = false;
      state.payments = action.payload;
    },

    getOutreachSuccess(state, action) {
      state.isLoading = false;
      state.outreach = action.payload;
    },

    getSurveySuccess(state, action) {
      state.isLoading = false;
      state.surveys = action.payload;
    },
    getOneOutreachSuccess(state, action) {
      state.isLoading = false;
      state.outreachEdit = action.payload;
    },
    getMeetSuccess(state, action) {
      state.isLoading = false;
      state.meet = action.payload;
    },
    // CREATE Jobs
    createEventSuccess(state, action) {
      const newJob = action.payload;
      state.isLoading = false;
      state.jobs = [...state.jobs, newJob];
    },

    // CREATE Jobs
    createPaymentSuccess(state, action) {
      const newJob = action.payload;
      state.isLoading = false;
      state.payments = [...state.payments, newJob];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.jobs.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.jobs = updateEvent;
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const { eventId } = action.payload;
      const deleteEvent = state.jobs.filter((event) => event.id !== eventId);
      state.jobs = deleteEvent;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getJobs() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let authToken = await getAuthId();
      const response = await axios.get(`/sellers/${authToken}`);
      dispatch(slice.actions.getEventsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPayments() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let authToken = await getAuthId();
      const response = await axios.get(`/payments/${authToken}`);
      dispatch(slice.actions.getPaymentsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOutreach(companyId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let authToken = await getAuthId();
      const response = await axios.get(`/leads/${authToken}/${companyId}`);
      dispatch(slice.actions.getOutreachSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOutreachCompany(sellerId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let authToken = await getAuthId();
      const response = await axios.get(`/leads/${sellerId}/${authToken}`);
      dispatch(slice.actions.getOutreachSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSurveysCompany(sellerId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let authToken = await getAuthId();
      const response = await axios.post(`/getSurveys/`, {
        companyId: authToken,
        sellerId,
      });
      dispatch(slice.actions.getSurveySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOutreachById(outreachId: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/outreach/${outreachId}`);
      dispatch(slice.actions.getOneOutreachSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMeetByIds(sellerId: any, companyId: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/meet/${sellerId}/${companyId}`);
      dispatch(slice.actions.getMeetSuccess(response.data[0]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createEvent(newEvent: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/createJobAddSeller', newEvent);
      dispatch(slice.actions.createEventSuccess(response.data.event));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateEvent(
  eventId: string,
  updateEvent: Partial<{
    allDay: boolean;
    start: Date | null;
    end: Date | null;
  }>
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/calendar/events/update', {
        eventId,
        updateEvent,
      });
      dispatch(slice.actions.updateEventSuccess(response.data.event));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteEvent(eventId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/calendar/events/delete', { eventId });
      dispatch(slice.actions.deleteEventSuccess({ eventId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
