import { ReactNode } from 'react';
import { Container, Alert, AlertTitle } from '@mui/material';
import useAuth from '../hooks/useAuth';
import LoadingScreen from '../components/LoadingScreen';
// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  accessibleRoles: string[];
  children: ReactNode | string;
};

const useCurrentRole = () => {
  const { user } = useAuth();
  const type = user?.isSeller ? 'seller' : 'company';
  return type;
};

const useCurrentAdmin = () => {
  const { user } = useAuth();
  const role = user?.role;
  return role;
};

export default function RoleBasedGuard({ accessibleRoles, children }: RoleBasedGuardProp) {
  const { isAuthenticated, isInitialized } = useAuth();
  const currentRole = useCurrentRole();
  const currentAdmin = useCurrentAdmin();
  if (!isInitialized) {
    return <LoadingScreen />;
  }
  console.log(currentAdmin);
  if (
    !isAuthenticated ||
    !(accessibleRoles.includes(currentRole) || accessibleRoles.includes(currentAdmin))
  ) {
    return (
      <Container sx={{ paddingTop: '40vh' }}>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
