import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';

import { BoxProps, Typography } from '@mui/material';


// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Typography
      component="span"
      variant="h3"
      sx={{ color: 'primary.main', fontFamily: 'shrik', textDecorationColor: 'transparent' }}
    >
      Seller
    </Typography>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <RouterLink to="/" style={{ textDecorationColor: 'transparent' }}>
      {logo}
    </RouterLink>
  );
}
