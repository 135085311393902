import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
// @types
import { SellersState } from '../../@types/sellers';
//
import { dispatch } from '../store';
import { getAuthId } from '../../contexts/FirebaseContext';
// ----------------------------------------------------------------------

const initialState: SellersState = {
  isLoading: true,
  error: null,
  sellers: [],
  companies: [],
  applications: [],
  tests: [],
  submissions: [],
  submissionById: null,
  submissionByTestId: [],
};

const slice = createSlice({
  name: 'sellers',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET JOBS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.sellers = action.payload;
    },

    getCompaniesSuccess(state, action) {
      state.isLoading = false;
      state.companies = action.payload;
    },

    getAppSuccess(state, action) {
      state.isLoading = false;
      state.applications = action.payload;
    },

    getTestsSuccess(state, action) {
      state.isLoading = false;
      state.tests = action.payload;
    },

    getSubmissionsSuccess(state, action) {
      state.isLoading = false;
      state.submissions = action.payload;
    },

    startSubmissionId(state) {
      state.isLoading = true;
      state.submissionById = null;
    },

    getSubmissionIdSuccess(state, action) {
      state.isLoading = false;
      state.submissionById = action.payload;
    },

    startSubmissionTestId(state) {
      state.isLoading = true;
      state.submissionByTestId = [];
    },

    getSubmissionTestIdSuccess(state, action) {
      state.isLoading = false;
      state.submissionByTestId = action.payload;
    },

    // CREATE Jobs
    createEventSuccess(state, action) {
      const newJob = action.payload;
      state.isLoading = false;
      state.sellers = [...state.sellers, newJob];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.sellers.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.sellers = updateEvent;
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const { eventId } = action.payload;
      const deleteEvent = state.sellers.filter((event) => event.id !== eventId);
      state.sellers = deleteEvent;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function getSubmissions() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let authToken = await getAuthId();
      const response = await axios.post('/submissions', { companyId: authToken });
      dispatch(slice.actions.getSubmissionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSubmissionsById(submissionId: string) {
  return async () => {
    dispatch(slice.actions.startSubmissionId());
    try {
      let authToken = await getAuthId();
      const response = await axios.post('/submissionById', {
        companyId: authToken,
        submissionId,
      });
      dispatch(slice.actions.getSubmissionIdSuccess(response.data[0]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSubmissionsByTestId(testId: string) {
  return async () => {
    dispatch(slice.actions.startSubmissionId());
    try {
      let authToken = await getAuthId();
      const response = await axios.post('/submissionByTestId', {
        companyId: authToken,
        testId,
      });
      console.log(response);
      dispatch(slice.actions.getSubmissionTestIdSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCompanies(sellerId?: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let authToken = await getAuthId();
      const response = await axios.get(`/apply/${sellerId ? sellerId : authToken}`);
      dispatch(slice.actions.getCompaniesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getApplicants(companyId?: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let authToken = await getAuthId();
      const response = await axios.get(`/applications/${companyId ? companyId : authToken}`);
      dispatch(slice.actions.getAppSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTests() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/tests`);
      dispatch(slice.actions.getTestsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSellers(companyId?: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let authToken = await getAuthId();
      const response = await axios.get(`/jobs/${companyId ? companyId : authToken}`);
      dispatch(slice.actions.getEventsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createEvent(newEvent: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/createJobAddSeller', newEvent);
      dispatch(slice.actions.createEventSuccess(response.data.event));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateEvent(
  eventId: string,
  updateEvent: Partial<{
    allDay: boolean;
    start: Date | null;
    end: Date | null;
  }>
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/calendar/events/update', {
        eventId,
        updateEvent,
      });
      dispatch(slice.actions.updateEventSuccess(response.data.event));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteEvent(eventId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/calendar/events/delete', { eventId });
      dispatch(slice.actions.deleteEventSuccess({ eventId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
