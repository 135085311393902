import { PlanFreeIcon, PlanStarterIcon, PlanPremiumIcon } from '../assets';

// ----------------------------------------------------------------------

const LICENSES = ['Hiring', 'Commission'];
const OPTIONS = [
  [
    'No Monthly Fee',
    'Unlimited Applications',
    'Unlimited Interviews',
    'Free Trials',
    '12% First-Year Base Upon Hiring',
  ],
  [
    'Free Replacement of Reps',
    'Data Tracking',
    'Unlimited Applications',
    'Unlimited Interviews',
    '$500/Rep/Month',
  ],
];
const COMMONS = [
  [`For filling full-time, high-level positions.`],
  [`For part-time commission-based work.`],
];
export const _homePlans = [...Array(2)].map((_, index) => ({
  license: LICENSES[index],
  commons: COMMONS[index],
  options: OPTIONS[index],
  icons: [],
}));

export const _pricingPlans = [
  {
    subscription: 'basic',
    icon: <PlanFreeIcon />,
    price: 0,
    caption: 'forever',
    lists: [
      { text: '3 prototypes', isAvailable: true },
      { text: '3 boards', isAvailable: true },
      { text: 'Up to 5 team members', isAvailable: false },
      { text: 'Advanced security', isAvailable: false },
      { text: 'Permissions & workflows', isAvailable: false },
    ],
    labelAction: 'current plan',
  },
  {
    subscription: 'starter',
    icon: <PlanStarterIcon />,
    price: 4.99,
    caption: 'saving $24 a year',
    lists: [
      { text: '3 prototypes', isAvailable: true },
      { text: '3 boards', isAvailable: true },
      { text: 'Up to 5 team members', isAvailable: true },
      { text: 'Advanced security', isAvailable: false },
      { text: 'Permissions & workflows', isAvailable: false },
    ],
    labelAction: 'choose starter',
  },
  {
    subscription: 'premium',
    icon: <PlanPremiumIcon />,
    price: 9.99,
    caption: 'saving $124 a year',
    lists: [
      { text: '3 prototypes', isAvailable: true },
      { text: '3 boards', isAvailable: true },
      { text: 'Up to 5 team members', isAvailable: true },
      { text: 'Advanced security', isAvailable: true },
      { text: 'Permissions & workflows', isAvailable: true },
    ],
    labelAction: 'choose premium',
  },
];
