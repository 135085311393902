// routes
import { PATH_ADMIN_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
};

const navConfig = [
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // MANAGEMENT : USER
      {
        title: 'Dashboard',
        path: PATH_ADMIN_DASHBOARD.general.app,
        icon: ICONS.kanban,
      },
      {
        title: 'Companies',
        path: PATH_ADMIN_DASHBOARD.user.companies,
        icon: ICONS.user,
      },
      {
        title: 'Sellers',
        path: PATH_ADMIN_DASHBOARD.user.sellers,
        icon: ICONS.user,
      },
    ],
  },
];

export default navConfig;
