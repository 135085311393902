import { createSlice } from '@reduxjs/toolkit';
import { EventInput } from '@fullcalendar/common';
// utils

import axios from '../../utils/axios';
// @types
import { AdminState } from '../../@types/admin';
//
import { dispatch } from '../store';
import { getAuthId } from '../../contexts/FirebaseContext';
// ----------------------------------------------------------------------

const initialState: AdminState = {
  isLoading: true,
  error: null,
  companies: [],
  sellers: [],
  outreach: [],
  jobs: [],
};

const slice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET companies
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.companies = action.payload;
    },
    // GET JOBS
    getJobsSuccess(state, action) {
      state.isLoading = false;
      state.jobs = action.payload;
    },
    // GET JOBS
    getClearState(state) {
      state.isLoading = false;
      state.jobs = [];
    },
    // GET companies
    getsellersSuccess(state, action) {
      state.isLoading = false;
      state.sellers = action.payload;
    },

    getOutreachSuccess(state, action) {
      state.isLoading = false;
      state.outreach = action.payload;
    },
    getOneOutreachSuccess(state, action) {
      state.isLoading = false;
      // state.outreachEdit = action.payload;
    },

    // CREATE companies
    createEventSuccess(state, action) {
      const newJob = action.payload;
      state.isLoading = false;
      state.companies = [...state.companies, newJob];
    },

    // CREATE companies
    createsellersuccess(state, action) {
      const newJob = action.payload;
      state.isLoading = false;
      state.sellers = [...state.sellers, newJob];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.companies.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.companies = updateEvent;
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const { eventId } = action.payload;
      const deleteEvent = state.companies.filter((event) => event.id !== eventId);
      state.companies = deleteEvent;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function getJobs(sellerId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/sellers/${sellerId}`);
      dispatch(slice.actions.getJobsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearJobs() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getClearState());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCompanies() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/companies`);
      dispatch(slice.actions.getEventsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSellersByCompany(companyId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/jobs/${companyId}`);
      dispatch(slice.actions.getsellersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSellers() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/sellers`);
      dispatch(slice.actions.getsellersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOutreach(sellerId: string, companyId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let authToken = await getAuthId();
      const response = await axios.get(`/leads/${sellerId}/${companyId}`);
      console.log(response);
      dispatch(slice.actions.getOutreachSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOutreachCompany(sellerId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let authToken = await getAuthId();
      const response = await axios.get(`/leads/${sellerId}/${authToken}`);
      dispatch(slice.actions.getOutreachSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOutreachById(outreachId: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/outreach/${outreachId}`);
      dispatch(slice.actions.getOneOutreachSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createEvent(newEvent: Omit<EventInput, 'id'>) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/calendar/events/new', newEvent);
      dispatch(slice.actions.createEventSuccess(response.data.event));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateEvent(
  eventId: string,
  updateEvent: Partial<{
    allDay: boolean;
    start: Date | null;
    end: Date | null;
  }>
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/calendar/events/update', {
        eventId,
        updateEvent,
      });
      dispatch(slice.actions.updateEventSuccess(response.data.event));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteEvent(eventId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/calendar/events/delete', { eventId });
      dispatch(slice.actions.deleteEventSuccess({ eventId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
