import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_SELLER_DASHBOARD, PATH_COMPANY_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated, user } = useAuth();

  if (isAuthenticated && !user?.isSeller) {
    return <Navigate to={PATH_COMPANY_DASHBOARD.root} />;
  } else if (isAuthenticated && user?.isSeller) {
    return <Navigate to={PATH_SELLER_DASHBOARD.root} />;
  }

  return <>{children}</>;
}
