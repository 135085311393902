import { Suspense, lazy, ElementType, useEffect } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// guards
import useAuth from '../hooks/useAuth';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import SellerDashboardLayout from '../layouts/seller-dashboard';
import CompanyDashboardLayout from '../layouts/company-dashboard';
import AdminDashboardLayout from '../layouts/admin-dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
// import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import {
  PATH_AFTER_LOGIN,
  PATH_AFTER_SELLER_LOGIN,
  PATH_AFTER_COMPANY_LOGIN,
  PATH_AFTER_ADMIN_LOGIN,
} from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
// import { getJobs } from '../redux/slices/jobs';
// import { getSellers } from '../redux/slices/sellers';
// // ----------------------------------------------------------------------
// import { useDispatch } from '../redux/store';
const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  // const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getJobs());
    // dispatch(getSellers());
  }, []);

  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: <Register />,
        },
        { path: 'login-unprotected', element: <Login /> },
        {
          path: 'register-seller',
          element: <Register />,
        },
        {
          path: 'register-company',
          element: (
            <GuestGuard>
              <RegisterCompany />
            </GuestGuard>
          ),
        },
        {
          path: 'sign-up/Gan',
          element: (
            <GuestGuard>
              <RegisterCompanyGan />
            </GuestGuard>
          ),
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'apply', element: <PageApply /> },
        { path: 'test/:testId', element: <TakeTest /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <RoleBasedGuard accessibleRoles={['admin']}>
          <DashboardLayout />
        </RoleBasedGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <AdminApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },

        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
            { path: 'invoice', element: <EcommerceInvoice /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new-post', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
      ],
    },

    // ADMIN Dashboard Routes
    {
      path: 'admin',
      element: (
        <RoleBasedGuard accessibleRoles={['admin']}>
          <AdminDashboardLayout />
        </RoleBasedGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_ADMIN_LOGIN} replace />, index: true },
        { path: 'app', element: <AdminApp /> },
        {
          path: 'companies',
          element: <CompanyListAdmin />,
        },
        {
          path: 'outreach/:sellerId/:companyId',
          element: <OutreachListAdmin />,
        },
        {
          path: 'companies/:userId/sellers',
          element: <SellerListByCompany />,
        },
        {
          path: 'sellers',
          element: <SellersListAdmin />,
        },
        {
          path: 'leads/:userId/:jobId',
          element: <UserCreate />,
        },
        {
          path: 'payments',
          element: <PaymentSellerList />,
        },
        { path: 'leads/:userId/:jobId/:outreachId/edit', element: <UserCreate /> },
        {
          path: 'outreach/:companyId/:jobId/:sellerId',
          element: <CompanyViewOutreach />,
        },
        { path: 'resources/:userId/:name', element: <CompanyDocsAdmin /> },
        {
          path: 'jobs/:userId',
          element: <CompanyListAdminBySeller />,
        },
        {
          path: 'jobs/:userId/:name',
          element: <CompanyInfoAdmin />,
        },
      ],
    },
    // seller Dashboard Routes
    {
      path: 'seller-dashboard',
      element: (
        <RoleBasedGuard accessibleRoles={['seller']}>
          <SellerDashboardLayout />
        </RoleBasedGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_SELLER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'resources', element: <HelpSeller /> },
        {
          path: 'apply',
          element: <CompanyCards />,
        },
        {
          path: 'jobs',
          element: <CompanyList />,
        },
        {
          path: 'jobs/:userId/:name',
          element: <CompanyInfoAdmin />,
        },
        {
          path: 'leads/:userId/:jobId',
          element: <UserCreate />,
        },
        {
          path: 'survey/:userId/:jobId',
          element: <SurveyCreate />,
        },
        {
          path: 'payments',
          element: <PaymentSellerList />,
        },
        {
          path: 'outreach/:companyId/:jobId',
          element: <OutreachList />,
        },
        { path: 'leads/:userId/:jobId/:outreachId/edit', element: <UserCreate /> },
        { path: 'user/account', element: <UserAccountSeller /> },
        { path: 'meet/:userId/accept', element: <SellerAcceptMeet /> },
      ],
    },
    // company Dashboard Routes
    {
      path: 'company-dashboard',
      element: (
        <RoleBasedGuard accessibleRoles={['company']}>
          <CompanyDashboardLayout />
        </RoleBasedGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_COMPANY_LOGIN} replace />, index: true },
        { path: 'app', element: <CompanyApp /> },
        { path: 'resources', element: <HelpPage /> },
        { path: 'app/:code', element: <CompanyApp /> },
        {
          path: 'sellers',
          element: <SellerList />,
        },
        {
          path: 'applications',
          element: <SubmitList />,
        },
        {
          path: 'test',
          element: <Tests />,
        },
        {
          path: 'sellers/:userId/:name',
          element: <SellerInfo />,
        },
        {
          path: 'testResults/:submissionId',
          element: <TestDashboard />,
        },
        {
          path: 'testResultsGroup/:submissionId',
          element: <TestDashboardByTest />,
        },
        {
          path: 'payments',
          element: <PaymentList />,
        },
        { path: 'documents', element: <CompanyDocs /> },
        {
          path: 'outreach/:companyId/:jobId/:sellerId',
          element: <CompanyViewOutreach />,
        },
        { path: 'surveys/:companyId/:jobId/:sellerId', element: <CompanyViewSurveys /> },
        { path: 'user/account', element: <UserAccountCompany /> },
        {
          path: 'meet/:userId',
          element: <CompanyAcceptApply />,
        },
        { path: 'meet/:userId/edit', element: <CompanyAcceptApply /> },
        { path: 'ai/email-intro', element: <AITools /> },
      ],
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
        { path: 'terms', element: <Terms /> },
        { path: 'privacy', element: <Privacy /> },
        { path: 'cookies', element: <Cookies /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <CompanyPage />, index: true },
        { path: 'sellers', element: <SellerPage /> },
        { path: 'company', element: <CompanyPage /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const CompanyViewOutreach = Loadable(lazy(() => import('../pages/dashboard/CompanyViewOutreach')));
const CompanyViewSurveys = Loadable(lazy(() => import('../pages/dashboard/CompanyViewSurveys')));
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const RegisterCompany = Loadable(lazy(() => import('../pages/auth/RegisterCompany')));
const RegisterCompanyGan = Loadable(lazy(() => import('../pages/auth/RegisterCompanyGan')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const AdminApp = Loadable(lazy(() => import('../pages/dashboard/AdminApp')));
const CompanyApp = Loadable(lazy(() => import('../pages/dashboard/CompanyApp')));
const HelpPage = Loadable(lazy(() => import('../pages/dashboard/HelpPage')));
const HelpSeller = Loadable(lazy(() => import('../pages/dashboard/HelpSeller')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductDetails'))
);
const EcommerceProductList = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductList'))
);
const EcommerceProductCreate = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductCreate'))
);
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const OutreachList = Loadable(lazy(() => import('../pages/dashboard/OutreachList')));
const OutreachListAdmin = Loadable(lazy(() => import('../pages/admin-dashboard/OutreachList')));
const SellersListAdmin = Loadable(lazy(() => import('../pages/admin-dashboard/SellerList')));
const CompanyListAdmin = Loadable(lazy(() => import('../pages/admin-dashboard/CompanyList')));
const CompanyListAdminBySeller = Loadable(
  lazy(() => import('../pages/admin-dashboard/CompanyListSeller'))
);
const CompanyCards = Loadable(lazy(() => import('../pages/seller-dashboard/CompanyCards')));
// const ApplicantCards = Loadable(lazy(() => import('../pages/company-dashboard/ApplicantCards')));
const Tests = Loadable(lazy(() => import('../pages/company-dashboard/Tests')));
const CompanyList = Loadable(lazy(() => import('../pages/seller-dashboard/CompanyList')));
const SellerInfo = Loadable(lazy(() => import('../pages/company-dashboard/SellerInfo')));
const TestDashboard = Loadable(lazy(() => import('../pages/company-dashboard/TestDashboard')));
const TestDashboardByTest = Loadable(
  lazy(() => import('../pages/company-dashboard/TestDashboardByTest'))
);

const SellerList = Loadable(lazy(() => import('../pages/company-dashboard/SellerList')));
const SubmitList = Loadable(lazy(() => import('../pages/company-dashboard/SubmitList')));

const CompanyAcceptApply = Loadable(
  lazy(() => import('../pages/company-dashboard/CompanyAcceptApply'))
);

const SellerAcceptMeet = Loadable(lazy(() => import('../pages/seller-dashboard/SellerAcceptMeet')));
const SellerListByCompany = Loadable(
  lazy(() => import('../pages/admin-dashboard/SellerListByCompany'))
);
const CompanyDocs = Loadable(lazy(() => import('../pages/company-dashboard/CompanyDocs')));
const CompanyDocsAdmin = Loadable(lazy(() => import('../pages/admin-dashboard/CompanyDocs')));
const CompanyInfo = Loadable(lazy(() => import('../pages/seller-dashboard/CompanyInfo')));
const CompanyInfoAdmin = Loadable(lazy(() => import('../pages/admin-dashboard/CompanyInfo')));
const PaymentList = Loadable(lazy(() => import('../pages/company-dashboard/PaymentList')));
const PaymentSellerList = Loadable(lazy(() => import('../pages/seller-dashboard/PaymentList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserAccountSeller = Loadable(lazy(() => import('../pages/seller-dashboard/UserAccount')));
const UserAccountCompany = Loadable(lazy(() => import('../pages/company-dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const SurveyCreate = Loadable(lazy(() => import('../pages/seller-dashboard/SurveyCreate')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
const AITools = Loadable(lazy(() => import('../pages/company-dashboard/AITools')));
// Main
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const SellerPage = Loadable(lazy(() => import('../pages/Seller')));
const CompanyPage = Loadable(lazy(() => import('../pages/Company')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const TakeTest = Loadable(lazy(() => import('../pages/TakeTest')));

const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const Terms = Loadable(lazy(() => import('../pages/Terms')));
const Cookies = Loadable(lazy(() => import('../pages/Cookies')));
const Privacy = Loadable(lazy(() => import('../pages/Privacy')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const PageApply = Loadable(lazy(() => import('../pages/PageApply')));
